import { useCallback, createContext, useContext } from "react";

const NullFormContext = createContext();

/**
 * The sole purpose of this component is to disable Google password manager
 */
function NullForm({ children, className, style }) {

    const context = useContext(NullFormContext);

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            return false;
        },
        []
    );

    if (context) {
        return (
            <div className={className}>
                {children}
            </div>
        )
    }

    return (
        <NullFormContext.Provider value={true}>
            <form autoComplete="off" className={className} style={style} onSubmit={onSubmit}>
                {children}
            </form>
        </NullFormContext.Provider>
    )
}

export default NullForm