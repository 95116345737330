import NullForm from "common/src/components/NullForm";
import { Button, TextField } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css'; //Example style, you can use another
import formatCurrency from "common/src/lib/format/currency";

const IS_LOCAL = process.env.REACT_APP_LOCAL_CATALOGUE;
const pfx = process.env.REACT_APP_ENV === "live" ? "" : `-${process.env.REACT_APP_ENV}`;
const API_URL = IS_LOCAL ?
    "http://localhost" :
    `https://api-catalogue${pfx}.thefloorr.com`;

const PAGE_SIZE = 60;

async function loadRetailers() {
    const url = new URL(`${API_URL}/retailers`);
    url.searchParams.append("limit", "1000");
    const response = await fetch(url);
    const data = await response.json();
    return data;
}

async function loadDesigners() {
    const url = new URL(`${API_URL}/designers`);
    url.searchParams.append("limit", "10000");
    const response = await fetch(url);
    const data = await response.json();
    return data;
}

async function loadCategories() {
    const url = new URL(`${API_URL}/categories`);
    const response = await fetch(url);
    const data = await response.json();
    return data;
}

async function loadColors() {
    const url = new URL(`${API_URL}/colors`);
    const response = await fetch(url);
    const data = await response.json();
    return data;
}


// function bool2str(value) {
//     if (value === "") return "";
//     if (value === true || value === "true") return "true";
//     return "false";
// }


const saleOptions = [
    { value: "all", name: "Full price and Sale" },
    { value: "full", name: "Full price only" },
    { value: "sale", name: "Sale only" },
]

const priceOptions = [
    { value: "", name: "Price" },

    { value: "usd/<250", name: "USD 0-250" },
    { value: "usd/250-500", name: "USD 250-500" },
    { value: "usd/500-1000", name: "USD 500-1000" },
    { value: "usd/1000-2000", name: "USD 1000-2000" },
    { value: "usd/2000-5000", name: "USD 2000-5000" },
    { value: "usd/5000-10000", name: "USD 5000-10000" },
    { value: "usd/10000-20000", name: "USD 10000-20000" },
    { value: "usd/>20000", name: "USD 20000+" },

    { value: "gbp/<250", name: "GBP 0-250" },
    { value: "gbp/250-500", name: "GBP 250-500" },
    { value: "gbp/500-1000", name: "GBP 500-1000" },
    { value: "gbp/1000-2000", name: "GBP 1000-2000" },
    { value: "gbp/2000-5000", name: "GBP 2000-5000" },
    { value: "gbp/5000-10000", name: "GBP 5000-10000" },
    { value: "gbp/10000-20000", name: "GBP 10000-20000" },
    { value: "gbp/>20000", name: "GBP 20000+" },

    { value: "eur/<250", name: "EUR 0-250" },
    { value: "eur/250-500", name: "EUR 250-500" },
    { value: "eur/500-1000", name: "EUR 500-1000" },
    { value: "eur/1000-2000", name: "EUR 1000-2000" },
    { value: "eur/2000-5000", name: "EUR 2000-5000" },
    { value: "eur/5000-10000", name: "EUR 5000-10000" },
    { value: "eur/10000-20000", name: "EUR 10000-20000" },
    { value: "eur/>20000", name: "EUR 20000+" },
];

const sortOptions = [
    { value: "relevance", name: "Order by relevance" },
    { value: "price_asc", name: "Price asc" },
    { value: "price_desc", name: "Price desc" },
    { value: "new_in", name: "New in" },
]


async function searchProducts(filters) {
    const url = new URL(`${API_URL}/`);
    const params = {};

    const payload = {
        ...filters,
        page_size: PAGE_SIZE,
        debug: true
    };

    params.body = JSON.stringify(payload);
    params.method = "POST";

    const response = await fetch(url, params);
    const data = await response.json();
    return data;
}


function ProductDebugInfo({ product, show = false, onToggle }) {

    const toggleDebugInfo = useCallback(
        (e) => {
            e.stopPropagation();
            onToggle(!show);
        },
        [onToggle, show]
    );

    const onInfoClick = useCallback(
        (e) => {
            e.stopPropagation();
        },
        []
    );

    if (!product) return null;
    if (!show) return null;

    return (
        <div className="json-output product-debug-info" onClick={onInfoClick}>
            <Editor
                value={JSON.stringify(product, null, 2)}
                onValueChange={() => { }}
                highlight={code => highlight(code, languages.json, 'json')}
                padding={10}
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 12,
                    border: "1px solid #ddd",
                    borderRadius: 5,
                    backgroundColor: "#f8f8f8"
                }}
            />
            <button onClick={toggleDebugInfo}>Hide</button>
        </div>
    )
}


function Product({ product }) {

    const [showDebug, setShowDebug] = useState(false);
    const { min_price, min_sale_price, currency } = product;
    const isSale = min_sale_price && min_sale_price < min_price;

    const onProductClick = useCallback(
        () => {
            setShowDebug(prev => !prev);
        },
        []
    );

    return (
        <div className="product" onClick={onProductClick}>
            <div className="image">
                <img src={product.images?.[0]} alt={product.name} />
            </div>
            <div className="id">{product.id}</div>
            {product.score && <div className="score">{product.score}</div>}
            {product.name_dist && <div className="score">n: {product.name_dist}</div>}
            {product.image_dist && <div className="score">i: {product.image_dist}</div>}
            {product.retailer && <div className="retailer">{product.retailer}</div>}
            <div className="brand">{product.brand}</div>
            {product.name && <div className="name">{product.name}</div>}
            {(min_price || min_sale_price) && (
                <div className="price">
                    {formatCurrency(min_sale_price || min_price, currency)}
                    {isSale && (<s>{formatCurrency(min_price, currency)}</s>)}
                </div>
            )}

            {showDebug &&
                <ProductDebugInfo
                    product={product}
                    show={showDebug}
                    onToggle={setShowDebug} />}

        </div>
    )
}

function OrderSelect({ value, onChage }) {
    return (
        <select value={value} onChange={onChage}>
            {sortOptions.map(option => (
                <option key={option.value} value={option.value}>{option.name}</option>
            ))}
        </select>
    )
}

function PriceSelect({ value, onChage }) {
    return (
        <select value={value} onChange={onChage}>
            {priceOptions.map(option => (
                <option key={option.value} value={option.value}>{option.name}</option>
            ))}
        </select>
    )
}


function SaleSelect({ value, onChage }) {
    return (
        <select value={value} onChange={onChage}>
            {saleOptions.map(option => (
                <option key={option.value} value={option.value}>{option.name}</option>
            ))}
        </select>
    )
}

function RetailersSelect({ value, onChage }) {
    const [retailers, setRetailers] = useState([]);

    const options = useMemo(
        () => {
            const options = [{ value: "", name: "Retailer" }];
            for (const retailer of retailers) {
                options.push({ value: retailer.id, name: retailer.name });
            }
            return options;
        },
        [retailers]
    );

    useEffect(
        () => void loadRetailers().then(setRetailers),
        []
    );

    return (
        <select value={value} onChange={onChage}>
            {options.map(retailer => (
                <option key={retailer.value} value={retailer.value}>{retailer.name}</option>
            ))}
        </select>
    )
}

function DesignersSelect({ value, onChage }) {

    const [designers, setDesigners] = useState([]);

    const options = useMemo(
        () => {
            const options = [{ value: "", name: "Designer" }];
            for (const designer of designers) {
                options.push({ value: designer.id, name: designer.name });
            }
            return options;
        },
        [designers]
    );

    useEffect(
        () => void loadDesigners().then(setDesigners),
        []
    );

    return (
        <select value={value} onChange={onChage}>
            {options.map(category => (
                <option key={category.value} value={category.value}>{category.name}</option>
            ))}
        </select>
    )
}



function ColorsSelect({ value, onChage }) {

    const [colors, setColors] = useState([]);

    const options = useMemo(
        () => {
            const options = [{ value: "", name: "Color" }];
            for (const color of colors) {
                if (!color.master) {
                    continue;
                }
                options.push({ value: color.id, name: color.name });
            }
            return options;
        },
        [colors]
    );

    useEffect(
        () => void loadColors().then(setColors),
        []
    );

    return (
        <select value={value} onChange={onChage}>
            {options.map(color => (
                <option key={color.value} value={color.value}>{color.name}</option>
            ))}
        </select>
    )
}


function CategoriesSelect({ value, onChage }) {

    const [categories, setCategories] = useState([]);

    const options = useMemo(
        () => {
            const options = [{ value: "", name: "Category" }];
            const unimap = {};
            for (const category of categories) {
                const ids = category.id.split("/");
                const parts = [];
                for (const id of ids) {
                    const value = [...parts, id].join("/");
                    parts.push(id);
                    if (unimap[value]) continue;
                    unimap[value] = true;
                    options.push({ value: value, name: value });
                }
            }
            return options;
        },
        [categories]
    );

    useEffect(
        () => void loadCategories().then(setCategories),
        []
    );

    return (
        <select value={value} onChange={onChage}>
            {options.map(category => (
                <option key={category.value} value={category.value}>{category.name}</option>
            ))}
        </select>
    )
}

function Stats({ stats }) {
    if (!stats) return null;
    const rows = Object.entries(stats).map(([key, value]) => (
        <div key={key} className="stats-row">
            <div className="key">{key}</div>
            <div className="value">{value}</div>
        </div>
    ));
    return (
        <div className="stats">
            <h3>Timing</h3>
            {rows}
        </div>
    )
}

function DebugInfo({ debugInfo }) {

    const [show, setShow] = useState(false);
    const toggleDebugInfo = useCallback(
        () => setShow(prev => !prev),
        []
    );

    if (!debugInfo) return null;

    return (
        <div className="debug-info">
            <button onClick={toggleDebugInfo}>Show debug info</button>
            {show && (
                <div className="json-output">
                    <Editor
                        value={JSON.stringify(debugInfo, null, 2)}
                        onValueChange={() => { }}
                        highlight={code => highlight(code, languages.json, 'json')}
                        padding={10}
                        style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 12,
                            border: "1px solid #ddd",
                            borderRadius: 5,
                            backgroundColor: "#f8f8f8"
                        }}
                    />
                    <button onClick={toggleDebugInfo}>Hide</button>
                </div>
            )}
        </div>
    )
}



function PageSearchTest() {

    const [page, setPage] = useState(0);
    const [volume, setVolume] = useState(0);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [productIds, setProductIds] = useState([]);
    const [products, setProducts] = useState([]);
    const [stats, setStats] = useState(null);
    const [info, setInfo] = useState({});
    const [debugInfo, setDebugInfo] = useState(null);
    const [region, setRegion] = useState("gb");
    const [outputCurrency, setOutputCurrency] = useState("");
    const [gender, setGender] = useState("female");
    const [category, setCategory] = useState("");
    const [designer, setDesigner] = useState("");
    const [retailer, setRetailer] = useState("");
    const [preowned, setPreowned] = useState(false);
    const [sale, setSale] = useState("");
    const [color, setColor] = useState("");
    const [price, setPrice] = useState("");
    const [order, setOrder] = useState("relevance");

    const onSearchChange = useCallback(
        e => setQuery(e.target.value),
        []
    );

    const onRegionChange = useCallback(
        e => setRegion(e.target.value),
        []
    );

    const onGenderChange = useCallback(
        e => setGender(e.target.value),
        []
    );

    const onCategoryChange = useCallback(
        e => setCategory(e.target.value),
        []
    );

    const onColorChange = useCallback(
        e => setColor(e.target.value),
        []
    );

    const onSaleChange = useCallback(
        e => setSale(e.target.value),
        []
    );

    const onOutputCurrencyChange = useCallback(
        e => setOutputCurrency(e.target.value),
        []
    );

    const onDesignerChange = useCallback(
        e => setDesigner(e.target.value),
        []
    );

    const onRetailerChange = useCallback(
        e => setRetailer(e.target.value),
        []
    );

    const onPriceChange = useCallback(
        e => setPrice(e.target.value),
        []
    );

    const onOrderChange = useCallback(
        e => setOrder(e.target.value),
        []
    );

    const onPreownedChange = useCallback(
        e => setPreowned(e.target.checked),
        []
    );

    const search = useCallback(
        async (volume = 0) => {
            setLoading(true);
            setVolume(volume);
            setPage(0);
            setProductIds([]);

            if (volume === 0) {
                setProducts([]);
                setStats(null);
                setDebugInfo(null);
                setInfo({});
            }

            try {
                const input = {
                    query,
                    region,
                    gender,
                    category,
                    designer,
                    price,
                    retailer,
                    color,
                    order_by: order,
                    volume
                }
                if (sale !== "") {
                    input.sale = sale;
                }
                if (outputCurrency !== "") {
                    input.convert_to_currency = outputCurrency;
                }
                if (preowned) {
                    input.pre_owned = "true";
                }
                for (const key in input) {
                    if (input[key] === "") {
                        delete input[key];
                    }
                }
                const data = await searchProducts(input);
                const products = data.product;
                const pdebug = data.product_debug;
                products.forEach(p => {
                    const debug = pdebug[p.id];
                    if (debug) {
                        p.name_dist = debug.name_dist;
                        p.image_dist = debug.image_dist;
                    }
                });

                if (volume === 0) {
                    setProductIds(data.id);
                    setProducts(products);
                    setStats(data.timing);
                    setInfo(data.info);
                    setDebugInfo(data.debug_info);
                }
                else {
                    setProductIds(prev => {
                        let newIds = [...prev, ...data.id];
                        return newIds.filter((id, index) => newIds.indexOf(id) === index);
                    });
                    setProducts(prev => {
                        const newProducts = products.filter(p => !prev.find(pp => pp.id === p.id));
                        return [...prev, ...newProducts]
                    });
                }
            }
            catch (error) {
                console.error(error);
                setProducts([]);
                setStats(null);
                setDebugInfo(null);
            }
            setLoading(false);
        },
        [query, region, gender, category, sale,
            designer, price, order, retailer,
            color, preowned, outputCurrency]
    );

    const loadMore = useCallback(
        async () => {
            setLoading(true);
            const nextPage = page + 1;
            setPage(nextPage);
            const pageIds = productIds.slice(nextPage * PAGE_SIZE, (nextPage + 1) * PAGE_SIZE);
            if (pageIds.length > 0) {
                const input = {
                    id: pageIds,
                    region,
                    gender
                }
                if (outputCurrency !== "") {
                    input.convert_to_currency = outputCurrency;
                }
                const data = await searchProducts(input);
                let products = data.product;
                setProducts(prev => {
                    const newProducts = products.filter(p => !prev.find(pp => pp.id === p.id));
                    return [...prev, ...newProducts]
                });
                setLoading(false);
            }
            else {
                search(volume + 1);
                setLoading(false);
            }

        },
        [region, gender, productIds, page, search, volume, outputCurrency]
    );

    const onKeyDown = useCallback(
        e => e.key === "Enter" && search(),
        [search]
    );

    useEffect(
        () => void search(),
        // eslint-disable-next-line
        []
    );

    return (
        <div className="page page-search-test">

            <NullForm className="toolbar"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                    alignItems: "flex-start"
                }}>
                <div style={{ display: "flex", gap: "10px" }}>
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        value={query}
                        style={{ width: 300 }}
                        placeholder="Search"
                        onKeyDown={onKeyDown}
                        onChange={onSearchChange} />
                    <select value={region} onChange={onRegionChange}>
                        <option value="global">All</option>
                        <option value="gb">GB</option>
                        <option value="us">US</option>
                        <option value="eu">EU</option>
                    </select>

                    <select value={gender} onChange={onGenderChange}>
                        <option value="female">Female</option>
                        <option value="male">Male</option>
                        <option value="unisex">Unisex</option>
                    </select>

                    <SaleSelect value={sale} onChage={onSaleChange} />

                    <select value={outputCurrency} onChange={onOutputCurrencyChange}>
                        <option value="">Convert to currency</option>
                        <option value="GBP">GBP</option>
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                    </select>
                </div>
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <CategoriesSelect value={category} onChage={onCategoryChange} />
                    {IS_LOCAL &&
                        <ColorsSelect value={color} onChage={onColorChange} />}

                    <RetailersSelect value={retailer} onChage={onRetailerChange} />
                    <DesignersSelect value={designer} onChage={onDesignerChange} />


                    <PriceSelect value={price} onChage={onPriceChange} />

                    <OrderSelect value={order} onChage={onOrderChange} />
                    <label>
                        <input
                            type="checkbox"
                            checked={preowned}
                            onChange={onPreownedChange} />
                        Pre-owned
                    </label>

                </div>
                <div>
                    <Button
                        variant="contained"
                        children={loading ? "Loading..." : "Search"}
                        disabled={loading}
                        onClick={() => search()} />
                </div>
            </NullForm>

            <div className="results">
                <div className="products">
                    {products.map(product => (
                        <Product key={product.id} product={product} />
                    ))}
                    {((page * PAGE_SIZE < productIds.length) || info?.has_more) &&
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <button
                                style={{ marginLeft: "auto", marginRight: "auto" }}
                                children={loading ? "Loading..." : "Load more"}
                                disabled={loading}
                                onClick={loadMore} />
                        </div>}
                </div>
                <div className="result-stats">
                    <Stats stats={stats} />
                    <DebugInfo debugInfo={debugInfo} />
                </div>
            </div>

        </div>
    )
}

export default PageSearchTest;