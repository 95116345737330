import { createContext, useContext, useCallback, useState } from "react";
import Context from "./Context";
import user from "common/src/user"
import { euCountryCodes } from "common/src/lib/countryCodes"
import { useOn } from "@kuindji/observable-react"

export const FilterApiContext = createContext(undefined);
export const FilterApiContextProvider = FilterApiContext.Provider;

const isInApp = !!process.env.APP_MODE;

export const apiKeys = {
    id: {
        clearable: false,
        resettable: false,
        changeable: false,
        queryParams: false
    },
    gender: {
        clearable: isInApp,
        resettable: true,
        changeable: true,
        queryParams: true,
        default: "female"
    },
    region: {
        clearable: false,
        resettable: true,
        changeable: true,
        queryParams: true
    },
    price: {
        clearable: true,
        resettable: true,
        changeable: true,
        queryParams: true
    },
    retailer: {
        clearable: true,
        resettable: true,
        changeable: true,
        queryParams: true
    },
    designer: {
        clearable: true,
        resettable: true,
        changeable: true,
        queryParams: true
    },
    order_by: {
        clearable: false,
        resettable: false,
        changeable: true,
        queryParams: true,
        default: "relevance"
    },
    page_size: {
        clearable: false,
        resettable: false,
        changeable: false,
        queryParams: false
    },
    convert_to_currency: {
        clearable: false,
        resettable: false,
        changeable: false,
        queryParams: false
    },
    convert_to_old_format: {
        clearable: false,
        resettable: false,
        changeable: false,
        queryParams: false,
        default: true
    },
    with_retailer_commission: {
        clearable: false,
        resettable: false,
        changeable: false,
        queryParams: false
    },
    query: {
        clearable: false,
        resettable: false,
        changeable: true,
        queryParams: true
    },
    sale: {
        clearable: false,
        resettable: true,
        changeable: true,
        queryParams: true,
        default: "full"
    },
    category: {
        clearable: true,
        resettable: true,
        changeable: true,
        queryParams: true
    },

};

// all api keys
export const allApiKeys = Object.keys(apiKeys);

// can take its value from query string
export const queryParamKeys = Object.keys(apiKeys).filter(k => apiKeys[k].queryParams);

// can be set to "All"
export const clearableKeys = Object.keys(apiKeys).filter(k => apiKeys[k].clearable);

// can be set to "All" or default value
export const resettableKeys = Object.keys(apiKeys).filter(k => apiKeys[k].resettable);

// fields that can be changed by user
export const userChangeableKeys = Object.keys(apiKeys).filter(k => apiKeys[k].changeable);

export const catalogueApiDefaults = Object.keys(apiKeys).reduce(
    (acc, key) => ({ ...acc, [key]: apiKeys[key].default }),
    {}
);

export const defaultData = {
    changesCount: 0,
    clearableChangesCount: 0,
    resettableChangesCount: 0,
    clearableFiltersCount: 0,
    appliedClearableFiltersCount: 0,
    resettableFiltersCount: 0,
    appliedResettableFiltersCount: 0,
    changes: [],
    clearableChanges: [],
    resettableChanges: []
};

export function getDefaultUserRegion() {
    const geo = user.geo();
    const country = geo.country.toLowerCase();

    if (country === "gb") {
        return "gb";
    }
    else if (country === "us") {
        return "us";
    }
    else if (country in euCountryCodes) {
        return "eu";
    }
    else {
        return "row";
    }
};

export function getCurrencyByRegion(region) {
    if (region === "gb") {
        return "GBP";
    }
    else if (region === "us") {
        return "USD";
    }
    else if (region === "eu") {
        return "EUR";
    }
    else {
        return "USD";
    }
};

export const shippingRegions = [
    { value: "gb", name: "UK (GBP)" },
    { value: "us", name: "US (USD)" },
    { value: "eu", name: "Europe (EUR)" },
    { value: "row", name: "Rest of the world (USD)" }
];

export const priceRanges = [
    { value: "<250", name: " <250" },
    { value: "250-500", name: " 250-500" },
    { value: "500-1000", name: " 500-1000" },
    { value: "1000-2000", name: " 1000-2000" },
    { value: "2000-5000", name: " 2000-5000" },
    { value: "5000-10000", name: " 5000-10000" },
    { value: "10000-20000", name: " 10000-20000" },
    { value: ">20000", name: " 20000+" },
];

export const shopByOptions = [
    { value: "full", name: "Full Price" },
    { value: "sale", name: "Sale" },
    { value: "all", name: "All" },
];

export const sortByOptions = [
    { value: "relevance", name: "Recommended" },
    { value: "new_in", name: "New in" },
    { value: "price_asc", name: "Price (low-high)" },
    { value: "price_desc", name: "Price (high-low)" },
];


export function getDefaultFilters() {
    const region = getDefaultUserRegion();
    return {
        ...catalogueApiDefaults,
        region,
        convert_to_currency: getCurrencyByRegion(region)
    }
};

function arrayDiffCount(arr1, arr2) {
    if (!arr1 && !arr2) {
        return 0;
    }
    if (!arr1 && arr2) {
        return arr2.length;
    }
    if (arr1 && !arr2) {
        return arr1.length;
    }
    const l1 = arr1.length;
    const l2 = arr2.length;
    const commonLength = arr1.filter(v => arr2.includes(v)).length;
    return l1 + l2 - (2 * commonLength);
}

export function useFilterState({ key, filter, applied }) {

    const getter = useCallback(
        () => {
            if (!key || !filter) {
                return undefined;
            }
            return applied ? filter.getApplied(key) : filter.get(key);
        },
        [key, filter, applied]
    );

    filter = useContext(FilterApiContext) || filter;
    const [value, setLocalValue] = useState(getter);

    const setValue = useCallback(
        (value) => {
            if (applied) {
                throw new Error("Cannot set value for applied filter");
            }
            if (Array.isArray(key)) {
                throw new Error("Cannot set value for multiple keys");
            }
            filter.set(key, value);
        },
        [applied, key, filter]
    );

    const onFilterChange = useCallback(
        (changedKeys) => {
            if (applied) {
                setLocalValue(filter.getApplied(key));
            }
            else {
                if (Array.isArray(key)) {
                    for (const k of key) {
                        if (changedKeys.includes(k)) {
                            setLocalValue(filter.get(key));
                            break;
                        }
                    }
                }
                else {
                    if (changedKeys.includes(key)) {
                        setLocalValue(filter.get(key));
                    }
                }
            }
        },
        [filter, key, applied, setLocalValue]
    );

    useOn(filter, applied ? "apply" : "change", onFilterChange);

    return [value, setValue];
}

export default class CatalogueFilterApi extends Context {

    _defaults = null;
    _initialData = null;
    appliedData = null;

    constructor(initialData) {
        super();
        this._appliedData = {};
        const defaults = getDefaultFilters();
        this._defaults = defaults;
        if (typeof initialData === "function") {
            initialData = initialData(this);
        }

        const data = { ...defaults, ...defaultData };

        for (const key in initialData) {
            if (initialData[key] !== undefined && initialData[key] !== null) {
                data[key] = initialData[key];
            }
        }

        this._initialData = data;

        this.data = { ...this.data, ...data };
        this.appliedData = { ...this.data, ...data };
    }

    getCatalogueApiData() {
        return this.get(allApiKeys);
    }

    getApplied(key) {
        if (typeof key === "string") {
            return this.appliedData[key];
        }
        else if (Array.isArray(key)) {
            const slice = {};
            key.forEach(k => {
                slice[k] = this.appliedData[k]
            })
            return slice;
        }
        else {
            const slice = {};
            const names = Object.keys(key);
            names.forEach(name => {
                slice[key[name]] = this.appliedData[name];
            })
            return slice;
        }
    }

    getAppliedCatalogueApiData() {
        return this.getApplied(allApiKeys);
    }

    unserialize(key, value) {
        if (key === "designer" && value) {
            return value.split(",");
        }
        return value;
    }

    serialize(key, value) {
        if (value === undefined || value === null) {
            return undefined;
        }
        if (key === "designer") {
            if (Array.isArray(value)) {
                return value.join(",");
            }
        }
        return value;
    }

    getSerialized(key) {
        return this.serialize(key, this.get(key));
    }

    onBeforeSet(key, value) {
        if ((value === undefined || value === null) && key in this._defaults) {
            return this._defaults[key];
        }
        else if ((value === undefined || value === null)) {
            return undefined;
        }
        return value;
    }

    _updateCounts() {
        const clearableFiltersCount = this.getClearableFiltersCount();
        const appliedClearableFiltersCount = this.getAppliedClearableFiltersCount();
        const resettableFiltersCount = this.getResettableFiltersCount();
        const appliedResettableFiltersCount = this.getAppliedResettableFiltersCount();

        let changesCount = 0;
        let clearableChangesCount = 0;
        let resettableChangesCount = 0;
        const changes = [];
        const clearableChanges = [];
        const resettableChanges = [];

        for (const key of allApiKeys) {
            const value = this.get(key);
            const appliedValue = this.getApplied(key);
            let change = 0;
            if (Array.isArray(value)) {
                change += arrayDiffCount(value, appliedValue);
            }
            else {
                change += value !== appliedValue;
            }
            changesCount += change;
            if (change > 0) {
                changes.push(key);
            }
            if (clearableKeys.includes(key)) {
                clearableChangesCount += change;
                if (change > 0) {
                    clearableChanges.push(key);
                }
            }
            if (resettableKeys.includes(key)) {
                resettableChangesCount += change;
                if (change > 0) {
                    resettableChanges.push(key);
                }
            }
        }

        this.set({
            changesCount,
            clearableChangesCount,
            resettableChangesCount,
            clearableFiltersCount,
            appliedClearableFiltersCount,
            resettableFiltersCount,
            appliedResettableFiltersCount,
            changes,
            clearableChanges,
            resettableChanges
        });
    }

    onChange(key, value, prev) {

        let extraKeys = [];

        if (key === "region") {
            this.suspendEvent("change");
            this.set("price", undefined);
            this.set("convert_to_currency", getCurrencyByRegion(value));
            this.resumeEvent("change");
            extraKeys = ["price", "convert_to_currency"];
        }
        if (key === "gender") {
            this.suspendEvent("change");
            this.set("category", undefined);
            this.resumeEvent("change");
            extraKeys = ["category"];
        }

        if (resettableKeys.includes(key)) {
            setTimeout(() => { this._updateCounts() });
        }

        return extraKeys;
    }

    _getFiltersCount(keys, data) {
        let count = 0;
        for (const key of keys) {
            const value = data[key];
            if (Array.isArray(value)) {
                count += value.length;
            }
            else {
                count += value !== catalogueApiDefaults[key] ? 1 : 0;
            }
        }
        return count;
    }

    _resetFilters(keys) {
        this.suspendEvent("change");
        for (const key of keys) {
            this.set(key, catalogueApiDefaults[key]);
        }
        this.resumeEvent("change");
        this.trigger("change", keys);
    }

    getAppliedClearableFiltersCount() {
        return this._getFiltersCount(clearableKeys, this.appliedData);
    }

    getAppliedResettableFiltersCount() {
        return this._getFiltersCount(resettableKeys, this.appliedData);
    }

    getClearableFiltersCount() {
        return this._getFiltersCount(clearableKeys, this.data);
    }

    getResettableFiltersCount() {
        return this._getFiltersCount(resettableKeys, this.data);
    }

    resetClearableFilters() {
        this._resetFilters(clearableKeys);
    }

    resetAllFilters() {
        this._resetFilters(resettableKeys);
    }

    hasAppliedClearableFilters() {
        return this.getAppliedClearableFiltersCount() > 0;
    }

    hasAppliedResettableFilters() {
        return this.getAppliedResettableFiltersCount() > 0;
    }

    hasClearableFilters() {
        return this.getClearableFiltersCount() > 0;
    }

    hasResettableFilters() {
        return this.getResettableFiltersCount() > 0;
    }

    apply(key = null) {
        if (key) {
            this.appliedData[key] = this.data[key];
        }
        else {
            this.appliedData = { ...this.data };
        }
        this.trigger("apply", { ...this.appliedData });
        setTimeout(() => { this._updateCounts() });
    }
}