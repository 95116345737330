import useQuery from "common/src/refactor/hooks/useQuery"
import api from "common/src/api"
import { useCallback, useEffect, useMemo, useState } from "react";
import Table from "common/src/components/table/Table";
import moment from "moment";
import dateFormats from "common/src/lib/date/formats";
import Switch from "@mui/material/Switch";
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

async function loadFiles() {
    const { files } = await api.backend.get("/catalogue/files");
    return { data: files };
}

async function loadTasks() {
    const { tasks } = await api.backend.get("/catalogue/tasks");
    return { data: tasks };
}

async function loadControls() {
    const controls = await api.backend.get("/catalogue/controls");
    return { data: controls };
}

const fileColumns = [
    {
        id: "source",
        name: "Source"
    },
    {
        id: "file_group_id",
        name: "Group"
    },
    {
        id: "region",
        name: "Region"
    },
    {
        id: "import_state",
        name: "State"
    }
];

const staleColumns = [
    {
        id: "source",
        name: "Source"
    },
    {
        id: "file_group_id",
        name: "Group"
    },
    {
        id: "region",
        name: "Region"
    },
    {
        id: "last_imported_at",
        name: "Last imported",
        render: (row) => moment(row.last_imported_at).format(dateFormats.dtime)
    }
]


export default function ControlPanel() {

    const [downloadEnabledLoading, setDownloadEnabledLoading] = useState(false);
    const [importEnabledLoading, setImportEnabledLoading] = useState(false);
    const [imageProcessingEnabledLoading, setImageProcessingEnabledLoading] = useState(false);
    const [schedulerEnabledLoading, setSchedulerEnabledLoading] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [reconnecting, setReconnecting] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [apiMenuOpen, setApiMenuOpen] = useState(false);
    const [fileControls, setFileControls] = useState({});
    const [pipelineMenuOpen, setPipelineMenuOpen] = useState(false);
    const [pipelineAnchorEl, setPipelineAnchorEl] = useState(null);

    const { data: files, refetch: refetchFiles } = useQuery(
        loadFiles,
        [],
        {
            enabled: true
        }
    );

    const { data: tasks, refetch: refetchTasks } = useQuery(
        loadTasks,
        [],
        {
            enabled: true
        }
    );

    const { data: controls, refetch: refetchControls } = useQuery(
        loadControls,
        [],
        {
            enabled: true
        }
    );

    const runningFiles = useMemo(
        () => {
            return files.filter(f => f.import_state === "STARTED" || f.import_state === "PENDING");
        },
        [files]
    );

    const failedFiles = useMemo(
        () => {
            return files.filter(f => f.import_state === "FAILED");
        },
        [files]
    );

    const staleFiles = useMemo(
        () => {
            return files.filter(f => {
                const lastImport = f.last_imported_at;
                if (!lastImport) {
                    return false;
                }
                if (lastImport < f.last_downloaded_at) {
                    return false;
                }
                const diff = moment().diff(moment(lastImport), "days");
                return diff > 7;
            });
        },
        [files]
    );

    const runningTasks = useMemo(
        () => {
            return tasks.filter(t => t.status === "running" || t.status === "pending");
        },
        [tasks]
    );

    const taskColumns = useMemo(
        () => {
            const taskColumns = [
                {
                    id: "task",
                    name: "Task"
                },
                {
                    id: "status",
                    name: "Status"
                },
                // {
                //     id: "created_at",
                //     name: "Created",
                //     render: (row) => moment(row.created_at).format(dateFormats.dtime)
                // },
                {
                    id: "duration",
                    name: "Duration",
                    render: row => {
                        if (row.status === "pending") {
                            return "";
                        }
                        if (row.task === "import" && row.state?.noMoreFiles) {
                            return "";
                        }
                        const updated_at = row.updated_at !== row.created_at ? row.updated_at : new Date();
                        const seconds = moment(updated_at).diff(moment(row.created_at), "seconds");

                        if (row.task === "clean" || row.task === "vacuum") {
                            if (seconds >= 59 && seconds < 61) {
                                return "<1m";
                            }
                        }

                        if (seconds < 60) {
                            return seconds + "s";
                        }
                        const minutes = Math.floor(seconds / 60);
                        const remainingSeconds = seconds % 60;
                        return minutes + "m " + remainingSeconds + "s";
                    }
                },
                {
                    id: "details",
                    name: "Details",
                    render: (row) => {
                        if (row.task === "import" && row.status !== "pending") {
                            if (row.state?.noMoreFiles) {
                                return "No more files to import"
                            }
                            const fileId = row.state?.fileId;
                            if (fileId) {
                                const file = files.find(f => f.id === fileId);
                                if (file) {
                                    return file.file_group_id + ": " + file.region;
                                }
                            }
                        }
                        else if (row.task === "clean") {
                            return row.params.fn.replace("clear_", "");
                        }
                        else if (row.task === "vacuum") {
                            return row.params.table;
                        }
                    }
                }
            ]
            return taskColumns;
        },
        [files]
    );


    const toggleFileControl = useCallback(
        async (fileId, control) => {
            setFileControls(prev => {
                return {
                    ...prev,
                    [fileId + control]: true
                }
            });
            await api.backend.post(`/catalogue/file/toggle-${control}`, {
                body: {
                    fileId
                }
            });
            await refetchFiles();
            setFileControls(prev => {
                return {
                    ...prev,
                    [fileId + control]: false
                }
            });
        },
        [refetchFiles]
    );

    const onFileDownloadEnabledChange = useCallback(
        async (fileId, e) => {
            await toggleFileControl(fileId, "download");
        },
        [toggleFileControl]
    );

    const onFileImportEnabledChange = useCallback(
        async (fileId, e) => {
            await toggleFileControl(fileId, "import");
        },
        [toggleFileControl]
    );

    const getFileRowDetails = useCallback(
        (row) => {
            const columns = [
                {
                    id: "property",
                    name: "Property",
                    className: "page-catalogue-control-panel-property-name"
                },
                {
                    id: "value",
                    name: "Value",
                    className: "page-catalogue-control-panel-property-value"
                }
            ]
            const rows = [
                {
                    property: "Download enabled",
                    value: (
                        <Switch
                            size="small"
                            disabled={fileControls[row.id + "download"]}
                            checked={row.download_enabled}
                            onChange={() => onFileDownloadEnabledChange(row.id)} />
                    )
                },
                {
                    property: "Import enabled",
                    value: (
                        <Switch
                            size="small"
                            disabled={fileControls[row.id + "import"]}
                            checked={row.import_enabled}
                            onChange={() => onFileImportEnabledChange(row.id)} />
                    )
                },
                {
                    property: "S3 filename",
                    value: row.s3_filename
                },
                {
                    property: "Source path",
                    value: row.source_path
                },
                {
                    property: "Last downloaded at",
                    value: row.last_downloaded_at ? moment(row.last_downloaded_at).format(dateFormats.dtime) : ""
                },
                {
                    property: "Last imported at",
                    value: row.last_imported_at ? moment(row.last_imported_at).format(dateFormats.dtime) : ""
                },
                {
                    property: "Download error",
                    value: row.last_download_error
                },
                {
                    property: "Import error",
                    value: row.last_import_error
                },
                {
                    property: "Images from region",
                    value: row.use_images_region
                },
                {
                    property: "Descriptions from region",
                    value: row.use_descriptions_region
                },
                {
                    property: "Custom currency",
                    value: row.use_currency
                }
            ];

            return (
                <Table noHeader cols={columns} rows={rows} variant="slim" />
            )
        },
        [fileControls, onFileDownloadEnabledChange, onFileImportEnabledChange]
    );

    const getTaskRowDetails = useCallback(
        (row) => {
            const columns = [
                {
                    id: "property",
                    name: "Property"
                },
                {
                    id: "value",
                    name: "Value"
                }
            ]
            const rows = [
                {
                    property: "Created at",
                    value: moment(row.created_at).format(dateFormats.dtime)
                },
                {
                    property: "Updated at",
                    value: moment(row.updated_at).format(dateFormats.dtime)
                }
            ];

            return (
                <Table noHeader cols={columns} rows={rows} variant="slim" />
            )
        },
        []
    );

    const getFileCellClassName = useCallback(
        (col, row) => {
            if (col.id === "import_state") {
                if (row.import_state === "PENDING") {
                    return "page-catalogue-control-panel-pending";
                }
                else if (row.import_state === "STARTED") {
                    return "page-catalogue-control-panel-running";
                }
                else if (row.import_state === "FAILED") {
                    return "page-catalogue-control-panel-failed";
                }
            }
            return "";
        },
        []
    );
    const getTaskCellClassName = useCallback(
        (col, row) => {
            if (col.id === "status") {
                if (row.status === "pending") {
                    return "page-catalogue-control-panel-pending";
                }
                else if (row.status === "running") {
                    return "page-catalogue-control-panel-running";
                }
                else if (row.status === "failed") {
                    return "page-catalogue-control-panel-failed";
                }
            }
            return "";
        },
        []
    );

    const toggleControl = useCallback(
        async (control, value) => {
            await api.backend.post("/catalogue/toggle-control", {
                body: {
                    control,
                    value: value ? "true" : "false"
                }
            });
            refetchControls();
        },
        [refetchControls]
    );

    const onDownloadEnabledChange = useCallback(
        async (e) => {
            setDownloadEnabledLoading(true);
            await toggleControl("download-enabled", e.target.checked);
            setDownloadEnabledLoading(false);
        },
        [toggleControl]
    );

    const onImportEnabledChange = useCallback(
        async (e) => {
            setImportEnabledLoading(true);
            await toggleControl("import-enabled", e.target.checked);
            setImportEnabledLoading(false);
        },
        [toggleControl]
    );

    const onImageProcessingEnabledChange = useCallback(
        async (e) => {
            setImageProcessingEnabledLoading(true);
            await toggleControl("image-processing-enabled", e.target.checked);
            setImageProcessingEnabledLoading(false);
        },
        [toggleControl]
    );

    const onSchedulerEnabledChange = useCallback(
        async (e) => {
            setSchedulerEnabledLoading(true);
            await toggleControl("scheduler-enabled", e.target.checked);
            setSchedulerEnabledLoading(false);
        },
        [toggleControl]
    );

    const onReloadDictionariesClick = useCallback(
        async () => {
            setReloading(true);
            await api.catalogue.post("/service/reload");
            setReloading(false);
        },
        []
    );

    const onResetConnectionClick = useCallback(
        async () => {
            setReconnecting(true);
            await api.catalogue.post("/service/reconnect");
            setReconnecting(false);
        },
        []
    );

    const onApiMenuClose = useCallback(
        () => {
            setApiMenuOpen(false);
        },
        []
    );

    const onApiMenuClick = useCallback(
        (e) => {
            setAnchorEl(e.currentTarget);
            setApiMenuOpen(true);
        },
        []
    );

    const onPipelineMenuClose = useCallback(
        () => {
            setPipelineMenuOpen(false);
        },
        []
    );

    const onPipelineMenuClick = useCallback(
        (e) => {
            setPipelineAnchorEl(e.currentTarget);
            setPipelineMenuOpen(true);
        },
        []
    );


    useEffect(
        () => {
            const itv = setInterval(() => {
                refetchFiles();
                refetchTasks();
                refetchControls();

                return () => {
                    clearInterval(itv);
                }
            }, 15000);
        },
        // eslint-disable-next-line
        []
    );

    return (
        <div className="page page-catalogue-control-panel">

            <div className="page-catalogue-control-panel-section"
                style={{
                    gridColumnStart: 1, gridColumnEnd: 3, display: "flex",
                    flexDirection: "row", gap: "1rem", justifyContent: "center"
                }}>





                <Button
                    disableRipple
                    onClick={onPipelineMenuClick}>Pipeline service</Button>

                <Menu
                    anchorEl={pipelineAnchorEl}
                    open={pipelineMenuOpen}
                    onClose={onPipelineMenuClose}
                >
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={onDownloadEnabledChange}
                                    disabled={downloadEnabledLoading}
                                    checked={controls.downloadEnabled} />
                            }
                            label="Download enabled" />
                    </MenuItem>
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={onImportEnabledChange}
                                    disabled={importEnabledLoading}
                                    checked={controls.importEnabled} />
                            }
                            label="Import enabled" />
                    </MenuItem>
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={onImageProcessingEnabledChange}
                                    disabled={imageProcessingEnabledLoading}
                                    checked={controls.imageProcessingEnabled} />
                            }
                            label="Image processing enabled" />
                    </MenuItem>
                    <MenuItem>
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={onSchedulerEnabledChange}
                                    disabled={schedulerEnabledLoading}
                                    checked={controls.schedulerEnabled} />
                            }
                            label="Scheduler enabled" />
                    </MenuItem>
                </Menu>

                <Button
                    disableRipple
                    onClick={onApiMenuClick}>Catalogue API service</Button>

                <Menu
                    anchorEl={anchorEl}
                    open={apiMenuOpen}
                    onClose={onApiMenuClose}
                >
                    <MenuItem
                        disabled={reloading}
                        onClick={onReloadDictionariesClick} disableRipple>
                        Reload dictionaries
                    </MenuItem>
                    <MenuItem
                        disabled={reconnecting}
                        onClick={onResetConnectionClick} disableRipple>
                        Reset database connection
                    </MenuItem>
                </Menu>
            </div>

            <div className="page-catalogue-control-panel-section">
                <h2>Currently importing</h2>
                <Table
                    cols={fileColumns}
                    rows={runningFiles}
                    expandKey="id"
                    expandable
                    renderDetails={getFileRowDetails}
                    variant="slim"
                    getCellClassName={getFileCellClassName} />
            </div>



            <div className="page-catalogue-control-panel-section">
                <h2>Current Tasks</h2>
                <Table
                    cols={taskColumns}
                    rows={runningTasks}
                    variant="slim"
                    expandKey="id"
                    expandable
                    getCellClassName={getTaskCellClassName}
                    renderDetails={getTaskRowDetails} />
            </div>

            <div className="page-catalogue-control-panel-section">
                <h2>Stale Files <span>(more than 7 days since last import)</span></h2>
                <Table
                    cols={staleColumns}
                    rows={staleFiles}
                    expandKey="id"
                    expandable
                    renderDetails={getFileRowDetails}
                    variant="slim"
                    getCellClassName={getFileCellClassName} />
            </div>


            <div className="page-catalogue-control-panel-section">
                <h2>Failed Files</h2>
                <Table
                    cols={fileColumns}
                    rows={failedFiles}
                    expandKey="id"
                    expandable
                    renderDetails={getFileRowDetails}
                    variant="slim"
                    getCellClassName={getFileCellClassName} />
            </div>






            <div className="page-catalogue-control-panel-section">
                <h2>All Files</h2>
                <Table
                    cols={fileColumns}
                    rows={files}
                    expandKey="id"
                    expandable
                    renderDetails={getFileRowDetails}
                    variant="slim"
                    getCellClassName={getFileCellClassName} />
            </div>
            <div className="page-catalogue-control-panel-section">
                <h2>Latest Tasks</h2>
                <Table
                    cols={taskColumns}
                    rows={tasks}
                    variant="slim"
                    expandKey="id"
                    expandable
                    getCellClassName={getTaskCellClassName}
                    renderDetails={getTaskRowDetails} />
            </div>
        </div >
    )
}