
import user from "common/src/user"
import authTypes from "./authTypes"
import getUserPromise from "./getUserPromise"

let AnalyticsTiming;

const prepareApiParams = function (params) {
    params = params || {};
    params.headers = params.headers || {};
    if (!params.headers["Content-type"]) {
        params.headers["Content-type"] = "application/json"
    }
    return params;
};

const prepareAuthParams = async function (params) {
    let token = await user.gwtToken()
    params.headers.Authorization = token;
    return params;
}


const callRevolutApi = async (apiName, method, path, params) => {
    params = prepareApiParams(params);

    if (apiName !== "public") {
        await getUserPromise(authTypes.USER);
        await prepareAuthParams(params);
    }

    params.method = method;
    const env = process.env.REACT_APP_ENV;
    const pfx = env === "live" ? "" : `-${env}`;
    let url = "";

    path = `/${apiName}${path}`;
    url = `https://api-revolut${pfx}.thefloorr.com${path}`;

    if (params.body) {
        params.body = JSON.stringify(params.body);
    }
    if (params.queryStringParameters) {
        url = new URL(url);
        Object.keys(params.queryStringParameters)
            .forEach(key => url.searchParams.append(key, params.queryStringParameters[key]))
    }

    return AnalyticsTiming.get().trackAsync(`api-revolut/${path}/${method}`)(
        () => fetch(url, params).then(r => r.json())
    )

    //return fetch(url, params).then(r => r.json());
};


const callRestApi2 = async (apiName, method, path, params) => {
    params = prepareApiParams(params);

    if (apiName !== "public") {
        await getUserPromise(authTypes.USER);
        await prepareAuthParams(params);
    }

    params.method = method;
    const env = process.env.REACT_APP_ENV;
    const pfx = env === "live" ? "" : `-${env}`;
    let url = "";
    let trackName = `api/${apiName}${path}/${method}`;

    if (apiName === "public" || apiName === "backend") {
        path = `/${apiName}${path}`;
        url = `https://api-backend${pfx}.thefloorr.com${path}`;
    }
    else {
        url = `https://api-${apiName}${pfx}.thefloorr.com${path}`;
    }

    if (params.body) {
        params.body = JSON.stringify(params.body);
    }
    if (params.queryStringParameters) {
        url = new URL(url);
        Object.keys(params.queryStringParameters)
            .forEach(key => url.searchParams.append(key, params.queryStringParameters[key]))
    }
    return AnalyticsTiming.get().trackAsync(trackName)(
        () => fetch(url, params).then(r => r.json())
    )

    //return fetch(url, params).then(r => r.json());
};



const callCatalogueApi = async (method, path, params) => {
    params = prepareApiParams(params);

    params.method = method;
    const env = process.env.REACT_APP_ENV;
    const pfx = env === "live" ? "" : `-${env}`;
    let url = "";

    if (process.env.REACT_APP_LOCAL_CATALOGUE) {
        url = `http://localhost:80${path}`;
    }
    else {
        url = `https://api-catalogue${pfx}.thefloorr.com${path}`;
    }

    if (params.body) {
        params.body = JSON.stringify(params.body);
    }
    if (params.queryStringParameters) {
        url = new URL(url);
        Object.keys(params.queryStringParameters)
            .forEach(key => url.searchParams.append(key, params.queryStringParameters[key]))
    }

    return fetch(url, params).then(r => r.json());

    //return fetch(url, params).then(r => r.json());
};


const reporting = {};
const backend = {};
const unauth = {};
const scraper = {};
const revolut = {};
const catalogue = {};

export function init(timingModule) {

    AnalyticsTiming = timingModule;

    reporting.get = async (path, params) => callRestApi2("reporting", "get", path, params);
    reporting.post = async (path, params) => callRestApi2("reporting", "post", path, params);

    backend.get = async (path, params) => callRestApi2("backend", "get", path, params);
    backend.post = async (path, params) => callRestApi2("backend", "post", path, params);
    backend.put = async (path, params) => callRestApi2("backend", "put", path, params);
    backend.del = async (path, params) => callRestApi2("backend", "delete", path, params);

    unauth.get = async (path, params) => callRestApi2("public", "get", path, params);
    unauth.post = async (path, params) => callRestApi2("public", "post", path, params);
    unauth.put = async (path, params) => callRestApi2("public", "put", path, params);
    unauth.del = async (path, params) => callRestApi2("public", "delete", path, params);

    scraper.get = async (params) => callRestApi2("scraper", "get", "/", params);

    revolut.get = async (path, params) => callRevolutApi("private", "get", path, params);
    revolut.post = async (path, params) => callRevolutApi("private", "post", path, params);
    revolut.put = async (path, params) => callRevolutApi("private", "put", path, params);
    revolut.del = async (path, params) => callRevolutApi("private", "delete", path, params);

    catalogue.get = async (path, params) => callCatalogueApi("get", path, params);
    catalogue.post = async (path, params) => callCatalogueApi("post", path, params);
}

export { reporting, unauth, backend, scraper, revolut, catalogue };