import Observable from "@kuindji/observable"

class Context extends Observable {

    data = {}

    constructor(data) {
        super();
        Object.assign(this.data, data);
    }

    batch(fn) {

        const log = {};
        let change = [];

        const interceptor = function (key, value) {
            if (key === "change") {
                change = [...change, ...value];
                change = change.filter((key, inx, self) => self.indexOf(key) === inx);
            }
            else {
                log[key] = value;
            }
        }

        this.intercept(interceptor);

        fn(this);

        this.stopIntercepting();

        Object.keys(log).forEach(key => {
            const moreKeys = this.onChange(key, log[key]);
            this.trigger(key, log[key]);
            if (moreKeys.length > 0) {
                change = [...change, ...moreKeys];
            }
        });
        if (change.length > 0) {
            this.onChanges(change);
            this.trigger("change", change);
        }
    }

    set(key, value) {

        if (!key) {
            return;
        }

        if (typeof key === "string") {
            value = this.onBeforeSet(key, value);
            if (this.data[key] !== value) {
                const prev = this.data[key];
                this.data[key] = value;
                const moreKeys = this.onChange(key, value, prev);
                this.trigger(key, value, prev);
                this.onChanges([key, ...moreKeys]);
                this.trigger("change", [key, ...moreKeys]);
            }
        }
        else {
            const values = key;
            const keys = [];
            let hasChanges = false;
            Object.keys(values).forEach(key => {
                const value = this.onBeforeSet(key, values[key]);
                if (this.data[key] !== value) {
                    const prev = this.data[key];
                    this.data[key] = value;
                    keys.push(key);
                    hasChanges = true;
                    const moreKeys = this.onChange(key, value, prev);
                    if (Array.isArray(moreKeys)) {
                        for (const k of moreKeys) {
                            keys.push(k);
                        }
                    }
                    this.trigger(key, value, prev);
                }
            })
            hasChanges && this.onChanges(keys);
            hasChanges && this.trigger("change", keys);
        }
    }

    get(key) {
        if (typeof key === "string") {
            return this.data[key];
        }
        else if (Array.isArray(key)) {
            const slice = {};
            key.forEach(k => {
                slice[k] = this.data[k]
            })
            return slice;
        }
        else {
            const slice = {};
            const names = Object.keys(key);
            names.forEach(name => {
                slice[key[name]] = this.data[name];
            })
            return slice;
        }
    }

    onBeforeSet(key, value) {
        return value;
    }

    onChange(key, value, prev) {
        return [];
    }

    onChanges(keys) {

    }

    getData() {
        return { ...this.data };
    }

    isEmpty() {
        const keys = Object.keys(this.data);
        if (keys.length === 0) {
            return true;
        }
        return keys.map(k => this.data[k]).filter(v => v !== null).length === 0;
    }

    $destroy() {
        this.trigger("destroy");
        super.$destroy();
        this.data = {};
    }
}

export class NamespacedContext extends Context {

    namespaces = {}
    defaultNamespace = null

    constructor(namespacedData, defaultNamespace) {
        super();
        this.defaultNamespace = defaultNamespace;
        Object.keys(namespacedData).forEach(name => {
            this.set(namespacedData[name], null, name);
        })
    }

    getNamespace(name) {
        if (!this.namespaces[name]) {
            const ns = new Context({ name });
            this.relay(ns, "*", null, ns.get("name") + ":");
            this.namespaces[name] = ns;
        }
        return this.namespaces[name];
    }

    get(key, namespace) {
        return this.getNamespace(namespace || this.defaultNamespace).get(key);
    }

    set(key, value, namespace) {
        return this.getNamespace(namespace || this.defaultNamespace).set(key, value);
    }

    getData(namespace) {
        return this.getNamespace(namespace || this.defaultNamespace).getData();
    }

    isEmpty(namespace) {
        return this.getNamespace(namespace || this.defaultNamespace).isEmpty();
    }

    $destroy() {
        Object.values(this.namespaces).forEach(ns => ns.$destroy());
        this.namespaces = {};
        super.$destroy();
    }

}

export default Context