import moment from "moment"
import api from "app/api"
import { loadCommissions } from "./commissions"
import {
    getPseCommission, calculateCommissions
} from "common/src/lambdalib/userPaymentSettings"

export const TYPE_FRI = 1;
export const TYPE_CONTRIBUTOR = 2;
export const TYPE_EXTRA = 3;

export const errors = {
    "USER_NOT_RELATED": "User is neither FRI nor Contributor in this sale",
    "ALREADY_APPROVED": "This commission was already approved for payment",
    "ANOTHER_USER_APPROVED": "Another user is already approved for this payment"
}

export const PER_PAGE = 50;
const usrGraph = "id givenName familyName avatar paymentSettings { friCommission contributorCommission }"

const dateToTimestamp = (d, time) => {
    if (d.indexOf("T") === -1) {
        d += ' ' + time;
    }
    return d;
}

const setBodyDates = (body, dateRange) => {
    if (dateRange.startDate && dateRange.endDate) {
        body.start = dateToTimestamp(dateRange.startDate, '00:00:00');
        body.end = dateToTimestamp(dateRange.endDate, '23:59:59');
    }
};

const idmap = (list) => {
    if (!list) {
        return [];
    }
    return list.map(item => {
        return typeof item === "string" ? item : item.id;
    });
};

const setBodyIds = (body, key, list) => {
    if (list && list.length > 0) {
        body[key] = idmap(list);
    }
};

const setBodyFlag = (body, key, value) => {
    if (value === true || value === false) {
        body[key] = value;
    }
};

const sortByDateGroup = (a, b) => {
    return a.date_group === b.date_group ? 0 :
        a.date_group < b.date_group ? 1 : -1;
}

const getRequestBody = (filters) => {
    const body = {};

    setBodyDates(body, filters);
    setBodyIds(body, "fris", filters.fris);
    setBodyIds(body, "contributors", filters.contributors);
    setBodyIds(body, "userGroups", filters.userGroups);
    setBodyIds(body, "months", filters.months);

    if (filters.group) {
        if (Array.isArray(filters.group) && filters.group.length > 0) {
            body.group = filters.group[0].id;
        }
        else if (typeof filters.group === "string") {
            body.group = filters.group;
        }
    }

    setBodyFlag(body, "approved", filters.approved);
    setBodyFlag(body, "withFriOrContributor", filters.withFriOrContributor);
    setBodyFlag(body, "withFri", filters.withFri);
    setBodyFlag(body, "withContributor", filters.withContributor);

    if (filters.contributorExcludeFri === true) {
        setBodyFlag(body, "contributorExcludeFri", filters.contributorExcludeFri);
    }

    //if (filters.pses && filters.pses.length > 0) {
    //setBodyIds(body, "friOrContributors", filters.pses);
    //}

    body.nonZero = true;

    return body;
};


export const loadMonths = async (originalFilters) => {
    let { pses, fris, contributors, ...filters } = originalFilters;
    filters = { ...filters, group: "month", approved: true };
    fris = [...idmap(pses), ...idmap(fris)].filter(id => !!id);
    contributors = [...idmap(pses), ...idmap(contributors)].filter(id => !!id);

    const friReq = getRequestBody({
        ...filters,
        withFri: true,
        fris: fris.length > 0 ? fris : undefined
    });
    const friResp = await api.reporting.post("/report/orders/bydate", { body: friReq });
    const conReq = getRequestBody({
        ...filters,
        withContributor: true,
        contributorExcludeFri: true,
        contributors: contributors.length > 0 ? contributors : undefined
    });
    const conResp = await api.reporting.post("/report/orders/bydate", { body: conReq });
    const getEmptySum = () => ({ saleAmount: 0, commissionAmount: 0 });

    const rows = friResp.rows.map(row => ({
        date_group: "" + row.date_group,
        fri: row,
        contributor: getEmptySum()
    }));

    conResp.rows.forEach(row => {
        const dg = "" + row.date_group;
        const ex = rows.find(r => "" + r.date_group === dg);
        if (ex) {
            ex.contributor = row;
        }
        else {
            rows.push({
                date_group: "" + row.date_group,
                fri: getEmptySum(),
                contributor: row
            })
        }
    });


    return rows.sort(sortByDateGroup);
}

export const loadMonthUsers = async (originalFilters, month) => {
    const getEmptySum = () => ({ saleAmount: 0, commissionAmount: 0 });
    const d = moment(month).utc();
    const startDate = d.startOf("month").toDate().toISOString();
    const endDate = d.endOf("month").toDate().toISOString();

    let { pses, fris, contributors, ...filters } = originalFilters;
    fris = [...idmap(pses), ...idmap(fris)].filter(id => !!id);
    contributors = [...idmap(pses), ...idmap(contributors)].filter(id => !!id);
    const friReq = getRequestBody({
        ...filters,
        withFri: true,
        startDate,
        endDate,
        approved: true,
        fris: fris.length > 0 ? fris : undefined
    });
    const conReq = getRequestBody({
        ...filters,
        withContributor: true,
        contributorExcludeFri: true,
        startDate,
        endDate,
        approved: true,
        contributors: contributors.length > 0 ? contributors : undefined
    });

    //const body = getRequestBody({ ...filters, startDate, endDate, approved: true });

    const friResp = await api.reporting.post("/report/orders/byfri", { body: friReq });
    const conResp = await api.reporting.post("/report/orders/bycontributor", { body: conReq });

    let rows = friResp.rows.map(row => ({
        userId: row.friId,
        fri: row,
        contributor: getEmptySum()
    }));

    conResp.rows.forEach(row => {
        const ex = rows.find(r => r.userId === row.referenceUserId);
        if (ex) {
            ex.contributor = row;
        }
        else {
            rows.push({
                userId: row.referenceUserId,
                fri: getEmptySum(),
                contributor: row
            });
        }
    });

    const userIds = rows.map(r => r.userId).filter((id, inx, self) => self.indexOf(id) === inx);
    const users = await api.user.list({ where: { id: { _in: userIds } } }, usrGraph);

    return rows.map(row => {
        row.user = users.find(u => u.id === row.userId);
        row.commissions = calculateCommissions(row, row.user);
        return row;
    });
}


export const loadUsers = async (originalFilters) => {
    let { pses, fris, contributors, ...filters } = originalFilters;
    fris = [...idmap(pses), ...idmap(fris)].filter(id => !!id);
    contributors = [...idmap(pses), ...idmap(contributors)].filter(id => !!id);
    const friReq = getRequestBody({
        ...filters,
        withFri: true,
        approved: true,
        fris: fris.length > 0 ? fris : undefined
    });
    const conReq = getRequestBody({
        ...filters,
        withContributor: true,
        contributorExcludeFri: true,
        approved: true,
        contributors: contributors.length > 0 ? contributors : undefined
    });

    const getEmptySum = () => ({ saleAmount: 0, commissionAmount: 0 });
    //const { rows } = await api.reporting.post("/report/orders/byreceiver", { body });    
    const friResp = await api.reporting.post("/report/orders/byfri", { body: friReq });
    const conResp = await api.reporting.post("/report/orders/bycontributor", { body: conReq });

    let rows = friResp.rows.map(row => ({
        userId: row.friId,
        fri: row,
        contributor: getEmptySum()
    }));

    conResp.rows.forEach(row => {
        const ex = rows.find(r => r.userId === row.referenceUserId);
        if (ex) {
            ex.contributor = row;
        }
        else {
            rows.push({
                userId: row.referenceUserId,
                fri: getEmptySum(),
                contributor: row
            });
        }
    });

    const userIds = rows.map(r => r.userId).filter((id, inx, self) => self.indexOf(id) === inx);
    const users = await api.user.list({ where: { id: { _in: userIds } } }, usrGraph);

    return rows.map(row => {
        row.user = users.find(u => u.id === row.userId);
        row.commissions = calculateCommissions(row, row.user);
        return row;
    });
}

export const loadUserMonths = async (filters, userId) => {
    filters = { ...filters, group: "month", approved: true };
    const friReq = getRequestBody({ ...filters, fris: [userId] });
    const friResp = await api.reporting.post("/report/orders/bydate", { body: friReq });
    const conReq = getRequestBody({ ...filters, contributors: [userId], contributorExcludeFri: true });
    const conResp = await api.reporting.post("/report/orders/bydate", { body: conReq });
    const getEmptySum = () => ({ saleAmount: 0, commissionAmount: 0 });
    const user = await api.user.get(userId, usrGraph);

    const rows = friResp.rows.map(row => ({
        date_group: "" + row.date_group,
        fri: row,
        contributor: getEmptySum()
    }));

    conResp.rows.forEach(row => {
        const dg = "" + row.date_group;
        const ex = rows.find(r => r.date_group === dg);
        if (ex) {
            ex.contributor = row;
        }
        else {
            rows.push({
                date_group: "" + row.date_group,
                fri: getEmptySum(),
                contributor: row
            })
        }
    });

    rows.forEach(row => {
        row.commissions = calculateCommissions(row, user);
    });


    return rows.sort((a, b) => {
        const v1 = (new Date(a.date_group)).getTime();
        const v2 = (new Date(b.date_group)).getTime();
        return v1 === v2 ? 0 :
            v1 < v2 ? -1 : 1;
    }).reverse();
}


export const loadUserCommissions = async (userId, month) => {
    const d = moment(month).utc();
    const startDate = d.startOf("month").toDate().toISOString();
    const endDate = d.endOf("month").toDate().toISOString();
    const filters = { noSum: false, approved: true, nonZero: true, startDate, endDate, users: [userId] };
    const { commissions } = await loadCommissions(filters);
    // const user = await api.user.get(userId, usrGraph);
    // const friRate = user?.paymentSettings?.friCommission || null;
    // const conRate = user?.paymentSettings?.contributorCommission || null;

    const rows = commissions.map(comm => {
        // calc fri/contributor commission
        const isFri = comm?.click?.product?.look?.friId === userId;
        const isCon = !isFri && comm?.click?.referenceUserId === userId;
        comm.roles = [];
        isFri && (comm.roles.push("FRI"));
        isCon && (comm.roles.push("Contributor"));

        // const friComm = isFri ? getFriCommission(comm.commissionAmount, friRate) : 0;
        //const conComm = isCon ? getContributorCommission(comm.commissionAmount, conRate) : 0;

        comm.userCommissionAmount = comm.pseCommission;
        //comm.userCommissionAmount = friComm + conComm;

        return comm;
    })
    return rows;
}



export const appoveCommissionForPayment = async (id, userId, paid = false) => {

    const user = await api.user.get(userId, usrGraph);
    const pseRate = user?.paymentSettings?.pseCommission || null;
    const comm = await api.networkOrder.get(id);
    const isFri = comm?.click?.product?.look?.friId === userId;
    const isContributor = comm?.click?.referenceUserId === userId;
    const inserts = [];

    if (!isFri && !isContributor) {
        throw new Error("USER_NOT_RELATED");
    }

    if (comm.approvedPayments && comm.approvedPayments.length > 0) {
        const exFri = comm.approvedPayments.find(p => p.type === TYPE_FRI);
        const exCont = comm.approvedPayments.find(p => p.type === TYPE_CONTRIBUTOR);

        if (isFri && exFri) {
            if (exFri.userId === userId) {
                throw new Error("ALREADY_APPROVED");
            }
            else {
                throw new Error("ANOTHER_USER_APPROVED");
            }
        }
        if (isContributor && exCont) {
            if (exCont.userId === userId) {
                throw new Error("ALREADY_APPROVED");
            }
            else {
                throw new Error("ANOTHER_USER_APPROVED");
            }
        }
    }

    if (isFri) {
        inserts.push({
            userId,
            networkOrderId: comm.id,
            type: TYPE_FRI,
            amount: getPseCommission(comm.commissionAmount, pseRate, comm.pseCommissionRate),
            currency: comm.currency,
            paymentMonth: comm.orderDate,
            paid
        });
    }
    else if (isContributor) {
        inserts.push({
            userId,
            networkOrderId: comm.id,
            type: TYPE_CONTRIBUTOR,
            amount: getPseCommission(comm.commissionAmount, pseRate, comm.pseCommissionRate),
            currency: comm.currency,
            paymentMonth: comm.orderDate,
            paid
        });
    }

    await api.userApprovedPayment.create(inserts);
}

export const setCommissionPaid = async (id, userId) => {
    const comm = await api.networkOrder.get(id);

    if (comm.approvedPayments && comm.approvedPayments.length > 0) {
        const ap = comm.approvedPayments[0];
        await api.userApprovedPayment.update(ap.id, { paid: true });
    }
    else {
        await appoveCommissionForPayment(id, userId, true);
    }
}

export const cancelCommissionPayment = async (commId, userId = null) => {

    const where = { networkOrderId: { _eq: commId } };
    if (userId) {
        where.userId = { _eq: userId };
    }

    await api.userApprovedPayment.remove(where);
}

export const loadExtraPayments = async (userId, month) => {

    const where = {
        userId: { _eq: userId },
        paymentMonth: { _eq: month },
        type: { _eq: TYPE_EXTRA }
    };
    const order = { createdAt: "desc" };
    const payments = await api.userApprovedPayment.list({ where, order });

    return payments;
}

export const addExtraPayment = async (userId, month, amount, currency, comment) => {

    amount = parseFloat(amount);

    await api.userApprovedPayment.create({
        userId,
        paymentMonth: month,
        amount,
        currency,
        comment,
        type: TYPE_EXTRA
    });
}

export const removeExtraPayment = async (id) => {
    await api.userApprovedPayment.remove(id);
}


export const saveUserCommissionRate = async (userId, type, rate) => {
    const ps = await api.userPaymentSettings.list({ where: { userId: { _eq: userId } } }, "id")
        .then(list => list.length > 0 ? list[0] : null);

    if (!rate) {
        rate = null;
    }

    if (ps) {
        await api.userPaymentSettings.update(ps.id, { [type]: rate });
    }
    else {
        await api.userPaymentSettings.create({
            userId,
            [type]: rate
        });
    }
}