
import { useCallback, useEffect, useMemo, useState } from "react"
import { batch, useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { useHistory } from "react-router-dom"

import Loader from "common/src/components/Loader"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Pagination from "common/src/components/Pagination"
import Avatar from "common/src/components/user/Avatar"
import UserDetails from "app/components/dialog/UserDetails"
import UserForm from "app/components/dialog/UserForm"
import Table from "common/src/components/table/Table"

import { ReactComponent as IconRefresh } from "common/src/svg/refresh.svg"
import { ReactComponent as IconDownload } from "common/src/svg/download.svg"

import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import { ui as ddUi } from "common/src/store/dialogs"
import { ui } from "app/store/page/people"
import * as actions from "app/actions/page/people"
import settings from "app/settings"
import df from "common/src/lib/date/formats"
import async from "common/src/lib/js/async"
import NullForm from "common/src/components/NullForm"
import useQueryParam from "common/src/hooks/useQueryParam"
import useSwallowEventCallback from "common/src/hooks/useSwallowEventCallback"
import api from "app/api"
import { data2csv, downloadCsv } from "common/src/lib/csv"


function renderAccess(u) {
    const a = u.details.access;
    return Object.keys(a).filter(k => a[k] === true).join(", ");
};


const cols = [
    {
        id: "name",
        name: "Name",
        className: "grid-nowrap",
        render: u => <><Avatar user={u} />{u.givenName} {u.familyName}</>,
        renderCsv: u => `${u.givenName} ${u.familyName}`
    },
    {
        id: "email",
        name: "Email",
        className: "grid-nowrap"
    },
    {
        id: "roles",
        name: "Roles",
        render: u => u.groups ?
            u.groups.split(",").map(g => g === "GPS" ? "PSE" : g).join(", ") :
            ""
    },
    {
        id: "access",
        name: "Access",
        className: "page-people-access-col",
        render: u => renderAccess(u)
    },
    {
        id: "flogin",
        name: "First login",
        className: "grid-nowrap",
        render: u => u.firstLoggedIn ? moment(u.firstLoggedIn).format(df.full) : ""
    },
    {
        id: "llogin",
        name: "Last login",
        className: "grid-nowrap",
        render: u => u.lastLoggedIn ? moment(u.lastLoggedIn).format(df.full) : ""
    },
    {
        id: "lastOnlineAt",
        name: "Last online in chat",
        className: "grid-nowrap",
        render: u => u.lastOnlineAt ? moment(u.lastOnlineAt).format(df.full) : ""
    }
];

async function retrieveAll(role, search) {

    const where = actions.prepareUserWhere({ role, search });
    let users = [];
    let offset = 0;
    const limit = 1000;

    while (true) {
        const list = await api.user.list({
            where,
            order: { createdAt: "desc" },
            offset,
            limit
        }, actions.USR_GRAPH);

        list.forEach(u => {
            u.lastOnlineAt = null;
            if (u.participantInChats && u.participantInChats.length > 0) {
                u.lastOnlineAt = u.participantInChats[0].lastOnlineAt;
            }
        })

        offset += limit;

        users = [...users, ...list];

        if (list.length < limit) {
            break;
        }
    }

    return users;
}


function PeopleList({ list, role, showUserDetails }) {

    const showRole = role !== "user";

    if (!showRole) {
        cols.splice(2, 1);
    }

    return (
        <Table
            variant="slim"
            getRowClass={u => !u.enabled ? "disabled" : ""}
            onRowClick={u => showUserDetails(u)}
            cols={cols}
            rows={list} />
    )
}


function People({ match: { params: { page = 1, role = "all" } } }) {

    const history = useHistory();
    const [user, setUser] = useState(null);
    const [search, setSearch] = useState("");
    const [downloading, setDownloading] = useState(false);
    const filter = useSelector(s => s.peoplePage.ui.people.filter);
    const dispatch = useDispatch();
    const list = useSelector(s => s.peoplePage.data.people);
    const { loading, count } = useSelector(s => s.peoplePage.ui.people);

    const gid = useQueryParam("g", null, null);
    const exRole = filter?.role;
    const exGid = filter?.gid || null;

    const refreshCls = useMemo(
        () => ['icon', 'icon-svg-fill', loading ? 'spinning' : ''].join(""),
        [loading]
    );

    const showUserDetails = useCallback(
        (user) => {
            setUser(user);
            dispatch(ddUi.show("user-details"));
        },
        [dispatch]
    );

    const showUserForm = useCallback(
        () => dispatch(ddUi.show("user-form")),
        [dispatch]
    );

    const doSearch = useCallback(
        () => {
            dispatch(ui.people.search(search));
            actions.loadPeopleList(0, true);
        },
        [search, dispatch]
    );

    const onPageChange = useCallback(
        (no) => {
            history.push(getUrl(routes.people, {
                role: role,
                page: no + 1
            }));
        },
        [role, history]
    );

    const onUserCreated = useCallback(
        (email) => {
            dispatch(ddUi.hide("user-form"));
            setSearch(email, () => {
                doSearch();
            });
        },
        [doSearch, dispatch]
    );

    const refresh = useSwallowEventCallback(
        () => actions.reloadPeopleList(),
        []
    );

    const download = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const users = await retrieveAll(role, search);
            const csv = data2csv(cols, users);
            await downloadCsv(csv, "people.csv");
            setDownloading(false);
        },
        [role, search]
    );

    useEffect(
        () => {
            if (role !== exRole || gid !== exGid) {
                async(() => {
                    batch(() => {
                        dispatch(ui.people.updateFilter({
                            key: "role",
                            value: role
                        }));
                        dispatch(ui.people.updateFilter({
                            key: "gid",
                            value: gid
                        }));
                    })
                    actions.loadPeopleList(page - 1, true);
                });

            }
            else {
                actions.loadPeopleList(page - 1);
            }
        },
        // eslint-disable-next-line
        [role, gid, page]
    );


    return (
        <>
            <div className="page page-people page-w-loading">
                {loading && <Loader size={64} />}

                <NullForm className="toolbar">
                    <Button
                        variant="contained"
                        children="Create user"
                        onClick={showUserForm} />
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        onKeyDown={e => e.key === "Enter" && doSearch()}
                        value={search}
                        label={search ? "" : "Search"}
                        onChange={e => setSearch(e.target.value)} />
                    <Button
                        variant="contained"
                        children="Search"
                        onClick={doSearch} />
                    <span className="text">
                        Total: {count}
                    </span>

                    <nav className="menu right">
                        <div className="menu-item">
                            <a href="/#"
                                className="menu-link"
                                onClick={refresh}>
                                <IconRefresh className={refreshCls} />
                            </a>
                        </div>
                        <div className="menu-item">
                            <a href="/#" className="menu-link"
                                onClick={download}>
                                {downloading ?
                                    <Loader size={24} /> :
                                    <IconDownload />}
                            </a>
                        </div>
                    </nav>
                </NullForm>

                <div className="grid-wrapper">
                    <PeopleList
                        list={list}
                        role={role}
                        showUserDetails={showUserDetails} />
                </div>

                <Pagination
                    page={page}
                    count={count}
                    perPage={settings.peoplePerPage}
                    onChange={no => onPageChange(no)} />
            </div>
            <UserDetails user={user} />
            <UserForm onCreate={email => onUserCreated(email)} />
        </>
    )
}

export default People