import { batch } from "react-redux"

import api from "app/api"
import store from "app/store"
import { data, ui, sets } from "common/src/store/catalogue"
import hub from "common/src/hub"
import user from "common/src/user"
import getUrl from "common/src/lib/url/get"
import routes from "app/routes"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { allApiKeys } from "common/src/refactor/lib/FilterApi"

const catalogueSetRequestCache = {};

export const loadColors = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.colors.length > 0) {
        return state.catalogue.data.colors;
    }

    const order = { name: "asc" };
    const colors = await api.catalogueColor.list({ order });
    store.dispatch(data.colors.set(colors));
    return colors;
}

export const loadMaterials = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.materials.length > 0) {
        return state.catalogue.data.materials;
    }

    const order = { name: "asc" };
    const materials = await api.catalogueMaterial.list({ order });
    store.dispatch(data.materials.set(materials));
    return materials;
}


export const loadCharacteristics = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.characteristics.length > 0) {
        return state.catalogue.data.characteristics;
    }

    const order = { name: "asc" };
    const characteristics = await api.catalogueCharacteristic.list({ order });
    store.dispatch(data.characteristics.set(characteristics));
    return characteristics;
}




export const loadCategories = async (force) => {
    const state = store.getState();

    if (!force && state.catalogue.data.categories.length > 0) {
        return state.catalogue.data.categories;
    }

    const order = { treePosition: "asc" };
    const categories = await api.catalogueCategory
        .list({ order });
    store.dispatch(data.categories.set(categories));
    return categories;
}

export const saveProduct = async (productCatalogueId) => {
    if (!user.loggedIn()) {
        hub.dispatch("app", "redir", getUrl(routes.signin));
        return;
    }
    const userId = user.id();
    return api.userSavedCatalogueProduct
        .create({ userId, productCatalogueId }, "returning { userId }")
        .then(() => {
            hub.dispatch("catalogue", "product-saved", productCatalogueId);
        })
        .catch(err => {
            hub.dispatch("error", "catalogue-product-save", err);
        });
}

export const unsaveProduct = async (productCatalogueId) => {
    if (!user.loggedIn()) {
        hub.dispatch("app", "redir", getUrl(routes.signin));
        return;
    }
    const userId = user.id();
    return api.userSavedCatalogueProduct.remove({
        userId: { _eq: userId },
        productCatalogueId: { _eq: productCatalogueId }
    })
        .then(() => {
            hub.dispatch("catalogue", "product-unsaved", productCatalogueId);
        })
        .catch(err => {
            hub.dispatch("error", "look-unsave", err);
        });
}




export const catalogueEuroCodes = "AT,BE,BG,HR,CY,CZ,DK,EE,FI,FR,DE," +
    "GR,HU,IE,IT,LV,LT,LU,MT,NL,PL," +
    "PT,RO,SK,SI,ES,SE,AL,AD,AM,BY,BA,FO,GE,GI,IS,IM,XK,LI,MK,MD," +
    "MC,ME,NO,RU,SM,RS,CH,TR,UA,VA";

export const catalogueRestCodes = "!AT,!BE,!BG,!HR,!CY,!CZ,!DK,!EE,!FI,!FR,!DE,!GR,!HU," +
    "!IE,!IT,!LV,!LT,!LU,!MT,!NL,!PL," +
    "!PT,!RO,!SK,!SI,!ES,!SE,!AL,!AD,!AM,!BY,!BA,!FO,!GE,!GI,!IS,!IM,!XK,!LI,!MK,!MD," +
    "!MC,!ME,!NO,!RU,!SM,!RS,!CH,!TR,!UA,!VA,!GB,!US";

export const catalogueRestCurrencies = "!USD,!GBP,!EUR";

export const catalogueFilterKeys = allApiKeys;


export function convertCurrency(product, convertTo, exchangeRates) {

    if (product.currency.toLowerCase() !== convertTo.toLowerCase()) {
        if (!product.original_price) {
            product.original_price = {
                price: product.price,
                sale_price: product.sale_price,
                currency: product.currency
            };
        }
        const rate = exchangeRates[product.original_price.currency.toLowerCase()][convertTo.toLowerCase()];
        product.currency_converted = true;
        product.price = product.original_price.price * rate;
        product.sale_price = product.original_price.sale_price * rate;
        product.currency = convertTo.toUpperCase();
    }

    return product;
}


export const PER_PAGE = 50;



const filterValue = (v) => {
    if (typeof v === "number") {
        return v;
    }
    if (!v) {
        return null;
    }
    if (v && (Array.isArray(v) || typeof (v) === "object") && "length" in v) {
        return [...v].sort().join(",");
    }
    return v;
}

export const getOptionChanges = (prev, options) => {
    const changes = [];

    catalogueFilterKeys.forEach(k => {
        if (filterValue(options[k]) !== filterValue(prev[k])) {
            changes.push(k);
        }
    });

    return changes;
};

export const getBodyChanges = (prev, body) => {
    if (!prev) {
        return Object.keys(body);
    }
    const changes = [];
    const keys1 = Object.keys(body);
    const keys2 = Object.keys(prev);
    const keys = [...keys1, ...keys2].filter((key, inx, self) => self.indexOf(key) === inx);

    keys.forEach(key => {
        if (filterValue(body[key]) !== filterValue(prev[key])) {
            changes.push(key);
        }
    });

    return changes;
};

export const hasResettingChange = (changes) => {
    return !!changes.find(key => key !== "start" &&
        key !== "limit" &&
        catalogueFilterKeys.indexOf(key) !== -1);
};

export const optionsDidChange = (prev, options) => {
    const changes = getOptionChanges(prev, options);
    return changes.length > 0;
};


const prepareRequestBody = (options) => {
    const body = {};
    const { perPage = PER_PAGE } = options;

    catalogueFilterKeys.forEach(k => {
        if (options[k]) {
            body[k] = options[k];
        }
    });

    body["page_size"] = perPage;
    body['convert_to_old_format'] = true;
    body['with_retailer_commission'] = true;

    if (!("region" in body)) {
        body['region'] = "gb";
        body["convert_to_currency"] = "GBP";
    }

    return body;
}

export const catalogueLoader = async (options, callbacks = {}) => {

    const body = prepareRequestBody(options);

    const storeState = store.getState();
    const requestId = (new Date()).getTime();

    let misc, prevBody, productIds;
    let { displayCurrency, page = 0, prevPage, perPage = PER_PAGE,
        setName = null, append = false, force = false,
        withSaved = false, withReactions = false,
        reactionsUserId = user.id() } = options;
    const exSet = storeState.catalogue.sets.products[setName];

    if (setName) {
        catalogueSetRequestCache[setName] = requestId;
        misc = storeState.catalogue.ui.products.misc;
        prevBody = misc[setName]?.filters || null;
        prevPage = misc[setName]?.page || 0;
        productIds = misc[setName]?.productIds || [];
    }
    else {
        prevBody = storeState.catalogue.ui.products.filter;
        prevPage = storeState.catalogue.ui.products.page || 0;
        productIds = storeState.catalogue.ui.products.misc?.productIds || [];
    }

    // const bodyChanges = prevBody && Object.keys(prevBody).length > 0 ?
    //     getBodyChanges({ ...prevBody }, { ...body }) :
    //     [];
    // if (prevBody && Object.keys(prevBody).length > 0 &&
    //     bodyChanges.length === 0 && exSet && exSet.length > 0 &&
    //     page === prevPage && !force) {
    //     return;
    // }

    batch(() => {

        if (setName) {
            misc = Object.assign(
                {},
                misc,
                {
                    [setName]: {
                        ...misc[setName],
                        loading: true,
                    }
                });
            store.dispatch(ui.products.setMisc(misc));
        }
        else {
            store.dispatch(ui.products.loading(true));
        }

        // if (hasResettingChange(bodyChanges)) {
        //     console.log("resetting change", bodyChanges)
        //     page = 0;
        //     append = false;
        //     if (setName) {
        //         store.dispatch(sets.products.set({ name: setName, value: [] }));
        //     }
        // }

    });

    let products, info, hasMore = false;

    if (page > 0) {
        const start = page * perPage;
        const ids = productIds.slice(start, start + perPage);
        if (ids.length > 0) {
            body["id"] = ids;
            // console.log("Fetching products by ids");
            // console.log(body);
            const response = await api.catalogue.post("/search", { body });
            products = response.product;
            hasMore = start + perPage < productIds.length;
        }
        else {
            products = [];
            hasMore = false;
        }
    }
    else {
        // console.log("Fetching products by search");
        // console.log(body);
        if (setName) {
            store.dispatch(sets.products.set({ name: setName, value: [] }));
        }
        // return;
        const response = await api.catalogue.post("/search", { body });
        products = response.product;
        productIds = response.id;
        hasMore = productIds.length > products.length;
    }

    if (setName && catalogueSetRequestCache[setName] !== requestId) {
        return;
    }

    if (callbacks.prefetchImages) {
        const pfimgRes = callbacks.prefetchImages(products);
        if (pfimgRes instanceof Promise) {
            await pfimgRes;
        }
    }

    const pids = products.map(p => p.product_web_id);
    let puids = products.map(p => p.uid);

    if (withSaved) {
        if (user.loggedIn() && pids.length > 0) {
            const saved = await api.userSavedCatalogueProduct.list({
                where: {
                    productCatalogueId: { _in: pids }
                }
            }, "productCatalogueId")
                .then(list => list.map(i => i.productCatalogueId));
            products.forEach(p => {
                p.saved = saved.indexOf(p.product_web_id) !== -1;
            })
        }
    }

    if (withReactions) {
        if (user.loggedIn() && pids.length > 0) {
            const cpsWhere = { productWebId: { _in: pids } };
            const cpsGraph = "id productWebId";
            const cps = await api.catalogueProduct.list({ where: cpsWhere }, cpsGraph);
            const cpsIds = cps.map(p => p.id);
            const rWhere = { catalogueProductId: { _in: cpsIds }, userId: { _eq: reactionsUserId } };
            const rGraph = "catalogueProductId reaction";
            const rs = await api.userReaction.list({ where: rWhere }, rGraph);
            products.forEach(p => {
                const pwid = p.product_web_id;
                const cpid = cps.find(p => p.productWebId === pwid)?.id;
                const reaction = rs.find(r => r.catalogueProductId === cpid)?.reaction;
                p.reaction = reaction || null;
            })
        }
    }

    batch(() => {
        if (setName) {
            misc = Object.assign(
                {},
                misc,
                {
                    [setName]: {
                        ...misc[setName],
                        loading: false,
                        loaded: true,
                        displayCurrency,
                        filters: body,
                        page,
                        hasMore,
                        productIds
                    }
                }
            );
            store.dispatch(ui.products.setMisc(misc));
        }
        else {
            store.dispatch(ui.products.filter(body));
            store.dispatch(ui.products.page(page));
            store.dispatch(ui.products.loading(false));
            store.dispatch(ui.products.hasMore(hasMore));

            if (page === 0) {
                store.dispatch(ui.products.misc({ productIds }));
            }
        }

        store.dispatch(data.products.merge(products));

        if (append) {
            const exSet = storeState.catalogue.sets.products[setName];
            if (!exSet) {
                store.dispatch(sets.products.set({ name: setName, value: puids }));
            }
            else {
                store.dispatch(sets.products.append({ name: setName, value: puids }));
            }
        }
        else {
            store.dispatch(sets.products.set({ name: setName, value: puids }));
        }
    })

    return { products, ids: productIds, hasMore };
}

export const setManualProducts = (setName, products) => {
    const storeState = store.getState();
    let misc = storeState.catalogue.ui.products.misc;
    misc = Object.assign(
        {},
        misc,
        {
            [setName]: {
                ...misc[setName],
                loading: false,
                loaded: true,
                count: products.length,
                filters: {},
                page: 0
            }
        }
    );
    batch(() => {
        store.dispatch(data.products.append(products));
        store.dispatch(sets.products.set({ name: setName, value: products.map(p => p.uid) }));
        store.dispatch(ui.products.setMisc(misc));
    });
};

export function useProductSet(setName) {
    const products = useSelector(s => s.catalogue.data.products);
    const ids = useSelector(s => s.catalogue.sets.products[setName]);
    const set = useMemo(
        () => {
            if (!ids) {
                return [];
            }
            return ids.map(id => products.find(p => p.uid === id));
        },
        [ids]
    );
    return set;
}

export const getProductsBySet = (setName) => {
    const state = store.getState();
    const products = state.catalogue.data.products;
    const ids = state.catalogue.sets.products[setName] || [];
    return ids.map(id => products.find(p => p.uid === id));
}

export const clearSet = (setName) => {
    const storeState = store.getState();
    const misc = { ...storeState.catalogue.ui.products.misc };
    delete misc[setName];

    batch(() => {
        store.dispatch(sets.products.unset(setName));
        store.dispatch(ui.products.setMisc(misc));
    });
}


export const loadFacets = async () => {

    const state = store.getState();

    if (state.catalogue.data.prices.length !== 0) {
        return;
    }

    batch(() => {
        store.dispatch(ui.retailers.loading(true));
        // store.dispatch(ui.prices.loading(true));
    });

    const retailers = await api.catalogue.get("/retailers", {
        queryStringParameters: {
            with_commission: true
        }
    });
    retailers.forEach(r => {
        r.commissionRate = parseInt(r.commission * 100);
        r.value = r.id;
        r.label = r.name;
    })

    // const prices = [
    //     100,
    //     200,
    //     300,
    //     400,
    //     500,
    //     1000,
    //     5000
    // ];

    batch(() => {
        store.dispatch(data.retailers.set(retailers));
        // store.dispatch(data.prices.set(prices));
        store.dispatch(ui.retailers.loading(false));
        store.dispatch(ui.prices.loading(false));
        store.dispatch(data.facetsLoaded.set(true));
    });
};